import { Icon, IconProps } from '@chakra-ui/react'
import { IconPackageExport, IconWorld } from '@tabler/icons-react'
import React from 'react'
import { DiscordIcon } from './DiscordIcon'
import { GitHubIcon } from './GitHubIcon'
import { LinkedinBoxIcon } from './LinkedInIcon'
import { SlackIcon } from './SlackIcon'

interface Props extends IconProps {
  source?: string
  size?: string | number
}

export function SourceIcon({ source, ...props }: Props) {
  const size = props.size ? `${props.size}px` : undefined

  switch (source?.toLowerCase()) {
    case 'slack':
      return <SlackIcon {...props} />
    case 'discord':
      return <DiscordIcon {...props} />
    case 'linkedin':
      return <LinkedinBoxIcon color="linkedin.700" {...props} />
    case 'github':
      return <GitHubIcon {...props} />
    case 'web':
      return <Icon as={IconWorld} width={size} height={size} color="gray.400" {...props} />
    default:
      return <Icon as={IconPackageExport} width={size} height={size} {...props} />
  }
}
